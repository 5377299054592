@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
html { -webkit-text-size-adjust: 100%; }
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.category-title {
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #101828;
  margin-top: 16px;
  margin-bottom: 16px;
}


.search-result-item {
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
}

.search-result-item:last-child {
  border: none;
}

.store-unselected {
  border: 2px solid #f2f4f7
}
.store-selected {
  border: 2px solid #70A401
}
.scroll-content {
  padding-top: 36px;
}

.nav {
  /*transition: all 0.1s linear;*/
  position: fixed;
  z-index: 2000;
  padding: 8px 0 0 0;
  width: 100%;
}
.scrollNav {
  /*transition: all 0.3s ease-in;*/
  z-index: 2000;
  background: #ffffff;
  width: 100%;
  box-shadow: 1px 10px 20px -11px rgba(0,0,0,0.59);
  -webkit-box-shadow: 1px 10px 20px -11px rgba(0,0,0,0.59);
  -moz-box-shadow: 1px 10px 20px -11px rgba(0,0,0,0.59);
}
.category-nav {
  overflow: scroll;
  white-space: nowrap;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.category-nav::-webkit-scrollbar {
  display: none;
}
.category-nav ul {
  margin-bottom: 10px;
}
.category-nav .MuiButton-root {
    margin-right: 20px;
}
.category-nav .is-active {
  background: #ed6c02;
  color: #fff;
}

table.topping td {
  padding: 10px 5px 10px 5px; 
}

table.topping td:first-child {
  width: 100%;
  padding-left: 0; 
}

table.topping td:last-child {
  padding-right: 0; 
}

input.address-autocomple {
  width: 100%;
  height: 34px;
  font-size: 16px;
  border: none;
  background: none;
}

input.address-autocomple {
  outline: none;
}

.back-button, .back-button button {
  padding-left: 0 !important;
}

.combo-group {
  margin-bottom: 16px;
  border-radius: 4px;
}

.combo-group-done {
  border-left: 4px solid #70a401;
}

.hnh-product:last-child>li:last-child {
  display: none;
}
.hnh-tabs {
  border-top: 2px solid #f4f6f7;
}

.hnh-tabs .MuiTabs-indicator{
  display: none;
}

.hnh-tab {
  background: #f4f6f7;
  text-transform: none;
  font-weight: bold;
  color: #777777;
  min-height: 55px;
}

.hnh-tab.Mui-selected {
  background: #fff;
  color: #777777;
}

.hnh-tab.Mui-selected .MuiSvgIcon-root {
  color: #ff5b5b;
}

.lazyload-wrapper {
  height: 100%;
}

.MuiCard-root.same-height,
.MuiCard-root.same-height>.MuiCardContent-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
@media screen and (orientation:portrait) {
  .bottom-button {
    padding-bottom: 34px !important;
  }
}
@media screen and (orientation:landscape) {
  .bottom-button {
    padding-bottom: 21px !important;
  }

  .left-menu-content {
    padding-left: 44px !important;
    width: 300px !important;
  }

  .MuiContainer-root,
  .MuiDialog-container .MuiToolbar-root,
  .MuiDialog-container .MuiDialogContent-root {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }
}

.pizza-option-select {
  font-size: 0.8em !important;
}

.MuiDialogContent-root a, .text-page a, .term-checkbox a {
  color: #337ab7;
}

